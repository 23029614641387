<template>
  <div>
    <UDropdown v-if="userSession.loggedIn" :items="items">
      <UButton color="white" trailing-icon="i-heroicons-chevron-down-20-solid">
        <UAvatar
          :src="userSession.data.user?.avatarUrl!"
          :alt="userSession.data.user?.name"
          size="3xs"
        />
        {{ userSession.data.user?.name }}
      </UButton>
    </UDropdown>
    <div v-else class="space-x-2">
      <UButton
        to="/login"
        icon="i-heroicons-arrow-left-on-rectangle"
        label="Login"
        color="black"
        external
      ></UButton>
    </div>
  </div>
</template>

<script setup lang="ts">
const userSession = useUserSession();
const items = [
  [
    {
      label: "Logout",
      icon: "i-heroicons-arrow-right-on-rectangle",
      click: () => userSession.clear(),
    },
  ],
];
</script>
