import type { UserSession } from '~/server/utils/session'

type ClientType = ReturnType<typeof useNuxtApp>['$client']
type UpdateDataType = Parameters<ClientType['user']['update']['mutate']>[0]

class UserState {

  state: globalThis.Ref<UserSession>
  constructor(
    public name: string, 
    public client: ClientType, 
  ) {
    this.state = useState<UserSession>(name, () => ({}))
  }

  get loggedIn() {
    if (!this.state.value) return false
    if (!this.state.value.user) return false
    return true
  }

  get user() {
    return this.state.value.user || null
  }

  get data() {
    return this.state.value
  }

  async fetch() {
    try {
      this.state.value = await this.client.session.getData.query()
    } catch (e) {
      console.error(e)
    }
  }

  async clear() {
    await this.client.session.logout.mutate()
    this.state.value = {}
  }

  async updateData(data: UpdateDataType) {
    return this.client.user.update.mutate(data)
  }
}

export const useUserSession = () => {
  const {$client} = useNuxtApp()
  const config = useRuntimeConfig()

  return reactive(new UserState(
    config.public.session.name,
    $client
  ))
}
