<script setup lang="ts">
const colorMode = useColorMode();
const { $colorMode } = useNuxtApp();
const route = useRoute();
const userSession = useUserSession();

watch(
  userSession,
  () => {
    if (!userSession?.loggedIn) {
      navigateTo("/");
    }
  },
  { deep: true }
);

function toggleColorMode() {
  const newColorMode = colorMode.preference === "dark" ? "light" : "dark";
  colorMode.preference = newColorMode;
}

useHead({
  htmlAttrs: { lang: "en" },
  link: [{ rel: "icon", href: "/icon.png" }],
});

useSeoMeta({
  viewport: {
    width: "device-width",
    initialScale: 1,
    maximumScale: 1,
  },
  title: "Nuxt Demo",
  description:
    "A Nuxt demo hosted on CloudFlare Pages with server-side rendering on the edge, authentication and queyring a SQLite database",
  ogImage: "/social-image.png",
  twitterImage: "/social-image.png",
  twitterCard: "summary_large_image",
});
</script>

<template>
  <div>
    <UContainer class="min-h-screen flex flex-col justify-center">
      <div class="mb-2 text-right flex justify-between">
        <Auth
          v-if="
            !route.path.includes('register') && !route.path.includes('login')
          "
        />
        <UButton
          square
          variant="ghost"
          color="black"
          :icon="
            $colorMode.preference === 'dark'
              ? 'i-heroicons-moon'
              : 'i-heroicons-sun'
          "
          @click="toggleColorMode"
        />
      </div>
      <NuxtPage />
      <footer class="text-center mt-2">
        <NuxtLink
          href="https://github.com/brendonmatos"
          target="_blank"
          class="text-sm text-gray-500 hover:text-gray-700"
        >
          GitHub
        </NuxtLink>
      </footer>
    </UContainer>
    <UNotifications />
  </div>
</template>

<style lang="postcss">
body {
  @apply antialiased font-sans text-gray-950 bg-gray-50 dark:bg-gray-950 dark:text-gray-50;
}
</style>
