import { createTRPCNuxtClient, httpBatchLink } from 'trpc-nuxt/client'
import superjson from 'superjson'
import { AppRouter } from '~/server/trpc/router'

export default defineNuxtPlugin({
  name: 'trpc-client',
  enforce: 'pre',
  order: 1,
  async setup() {
    /**
     * createTRPCNuxtClient adds a `useQuery` composable
     * built on top of `useAsyncData`.
     */
    const client = createTRPCNuxtClient<AppRouter>({
      transformer: superjson,
      links: [
        httpBatchLink({
          url: '/api/trpc'
        })
      ]
    })

    return {
      provide: {
        client
      }
    }
  }
})

export const useTRPC = () => {
  const { $client } = useNuxtApp()
  return $client
}

export type { User } from '~/server/trpc/trpc'
