import { default as dashboardB056aUFvflMeta } from "/opt/buildhome/repo/pages/admin/dashboard.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as indexCSx38ydJwoMeta } from "/opt/buildhome/repo/pages/onboarding/index.vue?macro=true";
import { default as _91_91id_93_93ZyXK5eA7sNMeta } from "/opt/buildhome/repo/pages/organizations/[[id]].vue?macro=true";
import { default as indexFs9vhivxMYMeta } from "/opt/buildhome/repo/pages/posts/index.vue?macro=true";
import { default as registerynsVf0yvMzMeta } from "/opt/buildhome/repo/pages/register.vue?macro=true";
import { default as indexioQMTXOG86Meta } from "/opt/buildhome/repo/pages/todos/index.vue?macro=true";
export default [
  {
    name: dashboardB056aUFvflMeta?.name ?? "admin-dashboard",
    path: dashboardB056aUFvflMeta?.path ?? "/admin/dashboard",
    meta: dashboardB056aUFvflMeta || {},
    alias: dashboardB056aUFvflMeta?.alias || [],
    redirect: dashboardB056aUFvflMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/admin/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login4dfr6kRqBoMeta?.name ?? "login",
    path: login4dfr6kRqBoMeta?.path ?? "/login",
    meta: login4dfr6kRqBoMeta || {},
    alias: login4dfr6kRqBoMeta?.alias || [],
    redirect: login4dfr6kRqBoMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexCSx38ydJwoMeta?.name ?? "onboarding",
    path: indexCSx38ydJwoMeta?.path ?? "/onboarding",
    meta: indexCSx38ydJwoMeta || {},
    alias: indexCSx38ydJwoMeta?.alias || [],
    redirect: indexCSx38ydJwoMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_93ZyXK5eA7sNMeta?.name ?? "organizations-id",
    path: _91_91id_93_93ZyXK5eA7sNMeta?.path ?? "/organizations/:id?",
    meta: _91_91id_93_93ZyXK5eA7sNMeta || {},
    alias: _91_91id_93_93ZyXK5eA7sNMeta?.alias || [],
    redirect: _91_91id_93_93ZyXK5eA7sNMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/organizations/[[id]].vue").then(m => m.default || m)
  },
  {
    name: indexFs9vhivxMYMeta?.name ?? "posts",
    path: indexFs9vhivxMYMeta?.path ?? "/posts",
    meta: indexFs9vhivxMYMeta || {},
    alias: indexFs9vhivxMYMeta?.alias || [],
    redirect: indexFs9vhivxMYMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/posts/index.vue").then(m => m.default || m)
  },
  {
    name: registerynsVf0yvMzMeta?.name ?? "register",
    path: registerynsVf0yvMzMeta?.path ?? "/register",
    meta: registerynsVf0yvMzMeta || {},
    alias: registerynsVf0yvMzMeta?.alias || [],
    redirect: registerynsVf0yvMzMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/register.vue").then(m => m.default || m)
  },
  {
    name: indexioQMTXOG86Meta?.name ?? "todos",
    path: indexioQMTXOG86Meta?.path ?? "/todos",
    meta: indexioQMTXOG86Meta || {},
    alias: indexioQMTXOG86Meta?.alias || [],
    redirect: indexioQMTXOG86Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/todos/index.vue").then(m => m.default || m)
  }
]